<template>
  <v-container fluid>
    <Editor :initial="dataItem" :pageMode="pageMode" :pageTitle="pageTitle" @data="save" />
  </v-container>
</template>
<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      dataItem: {},
      pageMode: "view",
      pageTitle: "Tier",
    };
  },
    watch: {
    "$route.params.id": {
      handler: "getTier",
      immediate: true
    }
  },
  methods: {
    getTier(id) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/tiers/${id}`)
        .then((res) => {
          self.dataItem = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      //logic comes here
      const url = "/tiers";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.loader = false;
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/channels/tiers");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>